<template>
  <div>
    <section class="section">
      <div class="container mt-60">
        <div class="row">
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4">
            <library :content.sync="content" />
          </div>
          <div class="col-xl-9 col-lg-8 col-md-8 col-sm-8">
            <div class="section-title">
              <div class="media features">
                <div class="icons m-0 rounded h1 text-primary px-3">
                  <i class="uil uil-apps"></i>
                </div>
              </div>
              <h4 class="title">จัดการหมวดหมู่</h4>
              <p class="border-bottom"></p>
            </div>

            <!-- header -->
            <div class="section-title">
              <h5 class="text-primary">วิธีกู้คืนหมวดหมู่</h5>
            </div>
            <!-- header -->
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12 mt-2">
                <div class="media features mb-3">
                  <div
                    class="icon text-center rounded-circle text-primary mr-3"
                  >
                    <h3 class="mb-0">1</h3>
                  </div>
                  <div class="media-body my-auto">
                    <h4 class="title mb-0">กดปุ่ม "Recycle Bin"</h4>
                  </div>
                </div>
                <a href="javascript: void(0);" v-b-modal.modal1>
                  <img
                    src="/images/screen/dataManagement/category7.png"
                    class="img-fluid mx-auto rounded-md shadow-lg d-block"
                  />
                </a>
                <b-modal
                  id="modal1"
                  hide-footer
                  hide-header
                  size="xl"
                  header-close-variant="white"
                  header-class="border-0"
                  content-class="border-0"
                  centered
                >
                  <img
                    src="/images/screen/dataManagement/category7.png"
                    class="img-fluid mx-auto shadow-lg d-block"
                  />
                </b-modal>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12 mt-2">
                <div class="media features mb-3">
                  <div
                    class="icon text-center rounded-circle text-primary mr-3"
                  >
                    <h3 class="mb-0">2</h3>
                  </div>
                  <div class="media-body my-auto">
                    <h4 class="title mb-0">
                      เลือกหมวดหมู่ที่ต้องการกู้คืน จากนั้นกดปุ่ม Recover
                    </h4>
                  </div>
                </div>
                <a href="javascript: void(0);" v-b-modal.modal2>
                  <img
                    src="/images/screen/dataManagement/category8.png"
                    class="img-fluid mx-auto rounded-md shadow-lg d-block"
                  />
                </a>
                <b-modal
                  id="modal2"
                  hide-footer
                  hide-header
                  size="xl"
                  header-close-variant="white"
                  header-class="border-0"
                  content-class="border-0"
                  centered
                >
                  <img
                    src="/images/screen/dataManagement/category8.png"
                    class="img-fluid mx-auto shadow-lg d-block"
                  />
                </b-modal>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12 mt-2">
                <div class="media features mb-3">
                  <div
                    class="icon text-center rounded-circle text-primary mr-3"
                  >
                    <h3 class="mb-0">3</h3>
                  </div>
                  <div class="media-body my-auto">
                    <h4 class="title mb-0">กดปุ่มยืนยัน</h4>
                  </div>
                </div>
                <a href="javascript: void(0);" v-b-modal.modal3>
                  <img
                    src="/images/screen/dataManagement/category9.png"
                    class="img-fluid mx-auto rounded-md shadow-lg d-block"
                  />
                </a>
                <b-modal
                  id="modal3"
                  hide-footer
                  hide-header
                  size="xl"
                  header-close-variant="white"
                  header-class="border-0"
                  content-class="border-0"
                  centered
                >
                  <img
                    src="/images/screen/dataManagement/category9.png"
                    class="img-fluid mx-auto shadow-lg d-block"
                  />
                </b-modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import library from "@/views/library/library.vue";
export default {
  data() {
    return {
      content: "categoryRestore",
    };
  },
  components: {
    library,
  },
};
</script>
